/**
 * Staging Environment properties file.
 */
export const environment = {
  production: false
};

/**
 * Application URLs.
 */
export const APP_URL = {
  API: 'https://learnstaging.uniathena.com/athenastg/api/',
  API_NEW: 'https://learnstaging.uniathena.com/athenaapi/api/',
  API_NEW_STG: 'https://lmsapistg.uniathena.com/api/',
  IP_GEOLOCATION: 'https://extreme-ip-lookup.com/json/?key=Q2XTHdI7x9BFavSEj8pj',
  OLD_LMS: 'https://ulearn.schneidestaging.in/',
  WEBSITE: 'https://uniathena.com/',
  NEW_LMS: 'https://stg.uniathena.com/lms/',
  ACADS_API: 'https://athenaapi.schneidestaging.in/',
  WEBSITE_ENCRYPT_API: 'https://athenaapi.uniathena.com/Encript/GetEncriptValue/',
  BASE_HREF_URL: 'https://stg.uniathena.com'
};

/**
 * Application configurations.
 */
export const APP_CONFIGURATION = {
  ADMIN: 24,
  CATEGORY_ID: 247,
  DISCUSSION_BOARD_ADMIN: 22,
  GUEST: 6
};

