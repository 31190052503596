import { Injectable } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { CommonService } from './common.service';
import { ApplicationNotifierConstant } from '../shared/constant/notifier.constant';
import { ApplicationNotifierService } from '../shared/component/application-notifier/application-notifier.service';
import { FormGroup } from '@angular/forms';
import { AppUtil } from '../shared/utils/app.util';
import { NotifierData } from '../shared/component/application-notifier/notifier-data.interface';
import { FileUpload } from '../interface/file-upload.interface';
import { BaseService } from './base.service';
import { AuthenticateService } from './authenticate.service';
import { AppConfig } from '../shared/config/app.config';
import { Params } from '@angular/router';
import { EndpointConstant } from '../shared/constant/endpoint.constant';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private authenticateService: AuthenticateService,
              private applicationNotifierService: ApplicationNotifierService,
              private baseService: BaseService,
              private commonService: CommonService) { }

  /**
   * Get the details for the form
   *
   * @param endpoint endpoint
   */
  getDetails(endpoint: string) {
    return this.baseService.get(endpoint);
  }

  /**
   * Delete the form details
   *
   * @param endpoint endpoint
   */
  deleteDetails(endpoint: string) {
    return this.baseService.delete(endpoint);
  }

  /**
   * Remove the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  removeDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Update the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  updateDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Save the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  saveDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * Register user.
   *
   * @param signUpForm is the form reference.
   * @param locationDetails is the location details
   * @param userDetails
   */
  registerUser(signUpForm: FormGroup, locationDetails: any, userDetails: any, queryParam: any)
    : Promise<any> {
    return new Promise((resolve, reject) => {
      if (signUpForm.valid) {
        const formData = {
          ...userDetails,
          ...signUpForm.value,
          is_academic: 1, //for registering the user in academic LMS
          [AppConfig.formDetails.countryCode.key]: locationDetails.countryCode,
          [AppConfig.formDetails.countryContactCode.key] : locationDetails.countryContactCode,
          [ApplicationConstant.formMetaFields.utmCampaign]: queryParam?.[ApplicationConstant.formMetaFields.utmCampaign],
          [ApplicationConstant.formMetaFields.utmMedium]: queryParam?.[ApplicationConstant.formMetaFields.utmMedium],
          [ApplicationConstant.formMetaFields.utmSource]: queryParam?.[ApplicationConstant.formMetaFields.utmSource],
          [ApplicationConstant.formMetaFields.utmPage]: queryParam?.[ApplicationConstant.formMetaFields.utmPage]
        }

        this.saveDetails(ApplicationConstant.endpoint.REGISTER_URL, formData).subscribe(resp => {
          this.authenticateService.setToken(resp.data.token);
          this.commonService.setUId(resp.data.user_details.uid);
          this.commonService.setFirstName(resp.data.user_details.first_name);
          this.commonService.loginProvider = ApplicationConstant.LOGIN_PROVIDERS.PASSWORD;
          resolve(resp.data);
        }, error => {
          console.error('Error', error);
          signUpForm.reset();
          reject(error);
        })
      } else {
        signUpForm.markAllAsTouched();
        reject('');
      }
    });
  }

  /**
   * Login user.
   *
   * @param signInForm is the form reference.
   */
  loginUser(signInForm: FormGroup, userDetails: any, queryParam: any): Promise<string> {
    return new Promise((resolve, reject) => {
      if (signInForm.valid) {
        const formData = {
          ...userDetails,
          ...signInForm.value,
          is_academic: 1, //for login the user in academic LMS
          [ApplicationConstant.formMetaFields.utmCampaign]: queryParam?.[ApplicationConstant.formMetaFields.utmCampaign],
          [ApplicationConstant.formMetaFields.utmMedium]: queryParam?.[ApplicationConstant.formMetaFields.utmMedium],
          [ApplicationConstant.formMetaFields.utmSource]: queryParam?.[ApplicationConstant.formMetaFields.utmSource],
          [ApplicationConstant.formMetaFields.utmPage]: queryParam?.[ApplicationConstant.formMetaFields.utmPage]
        }
        
        this.saveDetails(ApplicationConstant.endpoint.LOGIN_URL, formData).subscribe(resp => {
          if (resp.success) {
            this.authenticateService.setToken(resp.data.token);
            this.commonService.setUId(resp.data.user_details.uid);
            this.commonService.setFirstName(resp.data.user_details.first_name);
            this.commonService.loginProvider = ApplicationConstant.LOGIN_PROVIDERS.PASSWORD;
            resolve(resp.success);
          }
        }, error => {
          console.error('Error', error);
          signInForm.reset();
          if (error && error.error && error.error.data) {
            if (error.error.data.password && error.error.data.password[0] === 'The password is invalid.') {
              reject('Please enter the correct password!');
            } else if (error.error.data.mail && error.error.data.mail[0] === 'The selected mail is invalid.') {
              reject('Ohh!! Looks like you have not registered with us. Please register.');
            } else {
              reject('Invalid User Name or Password, Retry');
            }
          } else {
            reject('Invalid User Name or Password, Retry');
          }
        })
      } else {
        signInForm.markAllAsTouched();
        reject('');
      }
    });
  }

  /**
   * Save the UTM details from the url.
   *
   * @param queryParam is the param data from the url.
   * @param courseId is the id for the course.
   */
  getAndSaveUTMDetails(queryParam: Params, courseId: number) {

    const payload = {
      [ApplicationConstant.formMetaFields.utmCampaign]: queryParam?.[ApplicationConstant.formMetaFields.utmCampaign],
      [ApplicationConstant.formMetaFields.utmMedium]: queryParam?.[ApplicationConstant.formMetaFields.utmMedium],
      [ApplicationConstant.formMetaFields.utmSource]: queryParam?.[ApplicationConstant.formMetaFields.utmSource],
      [ApplicationConstant.formMetaFields.utmPage]: queryParam?.[ApplicationConstant.formMetaFields.utmPage],
      [ApplicationConstant.formMetaFields.utmPosition]: queryParam?.[ApplicationConstant.formMetaFields.utmPosition],
      [ApplicationConstant.formMetaFields.courseDetailId]: courseId
    };

    if (queryParam?.[ApplicationConstant.formMetaFields.utmCampaign]
      && queryParam?.[ApplicationConstant.formMetaFields.utmMedium]
      && queryParam?.[ApplicationConstant.formMetaFields.utmSource]
      && queryParam?.[ApplicationConstant.formMetaFields.utmPage]) {
      this.saveDetails(EndpointConstant.UTM_DETAILS_SAVE, payload).subscribe(value => {
      }, error => {
        console.error('Error', error);
      })
    }
  }

  /**
   * Get the details from the third party APIs.
   *
   * @param url request URL.
   * @param token authorization token.
   */
  getDetailsFromThirdParty(url: string, token: string) {
    return new Promise( (resp, err) => {
      this.baseService.getDetailsFromThirdParty(url, token).then(value => resp(value.json()))
        .catch(reason => err(reason));
    });
  }

  /**
   * Validate the form details
   *
   * @param endpoint endpoint
   * @param data form data
   */
  validateDetails(endpoint: string, data: any) {
    return this.baseService.post(endpoint, data);
  }

  /**
   * To display the country name to the auto complete.
   *
   * @param country field for the country
   */
  displayCountryName(country: any) {
    if (country) {
      if (typeof country === 'string' || country instanceof String) {
        return country;
      } else {
        return country.country_name;
      }
    }
  }

  /**
   * To display the Course name to the auto complete.
   *
   * @param Course field for the Course
   */
  displayCourseName(course: any) {
    if (course) {
      if (typeof course === 'string' || course instanceof String) {
        return course;
      } else {
        return course.course_name;
      }
    }
  }

  /**
   * Display block to the auto complete
   * @param uniEdge 
   * @returns 
   */
  displayBlockName(uniEdge: any) {
    if (uniEdge) {
      if (typeof uniEdge === 'string' || uniEdge instanceof String) {
        return uniEdge;
      } else {
        return uniEdge.block_name;
      }
    }
  }

  /**
   * Multiple file Upload.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param fileUploadKey  file key
   * @param fileUploadNameKey  filename key
   * @param form FormGroup
   * @param fileUpload file to be uploaded
   * @param parentId id of the details
   * @param source source id of the application
   * @param courseId id for the user applied
   */
  multipleUploadFile(event, fileConfigKey: any, fileUploadKey: any, fileUploadNameKey: any, form: FormGroup, fileUpload: Array<FileUpload>,
                     parentId: number, source: string, courseId: number) {

    // check for no. of files
    if (fileUpload.length < ApplicationConstant.maxFile) {
      // check for the file size less than 5MB.
      if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

        // check for file extension.
        if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

          const formData: FormData = new FormData();
          if (form.value[fileUploadNameKey.key] === null || form.value[fileUploadNameKey.key] === '') {
            form.controls[fileUploadNameKey.key].setValue(`${fileConfigKey.defaultTextValue} ${fileUpload.length + 1}`);
          }
          if (parentId) {
            formData.append(ApplicationConstant.formMetaFields.parentId, parentId.toString());
          }
          formData.append(fileConfigKey.text, form.value[fileUploadNameKey.key]);
          formData.append(fileConfigKey.key, event.target.files.item(0));
          formData.append('type', fileConfigKey.type);
          formData.append(ApplicationConstant.formMetaFields.userId, this.commonService.getUId().toString());
          formData.append('filename', AppUtil.createFileName(this.commonService.getUId(), fileConfigKey.name));
          formData.append(ApplicationConstant.formMetaFields.courseId, courseId.toString());
          formData.append(ApplicationConstant.formMetaFields.applicationSource, source);
          formData.append(ApplicationConstant.formMetaFields.temp_file, 'Y');

          this.saveDetails(ApplicationConstant.endpoint.UPLOAD_USER_FILE_URL, formData).subscribe(value => {
            event.target.value = null;
            AppUtil.multipleFileUpload(value.data, form, fileUploadNameKey.key, fileUploadKey.key, fileUpload,
              ApplicationNotifierConstant.file_upload);
          }, error => {
            console.log('error', error);
            event.target.value = null;
            this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
          });
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
            ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
      }
    } else {
      event.target.value = null;
      const errorMessage: NotifierData = Object.assign({}, ApplicationNotifierConstant.file_limit_error);
      errorMessage.message = `${errorMessage.message} ${ApplicationConstant.maxFile}`;
      this.applicationNotifierService.getNotifier(errorMessage);
    }
  }

  /**
   * Single Upload file with form data.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param fileUploadKey  file key
   * @param fileUploadNameKey  filename key
   * @param form FormGroup
   * @param fileUpload file to be uploaded
   */
  singleUploadFileWithForm(event, fileConfigKey: any, fileUploadKey: any, fileUploadNameKey: any, form: FormGroup, fileUpload: FileUpload) {

    // check for no. of files
    if (Object.keys(fileUpload).length === 0) {
      // check for the file size less than 5MB.
      if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

        // check for file extension.
        if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

          const formData: FormData = new FormData();
          if (form.value[fileUploadNameKey.key] === null || form.value[fileUploadNameKey.key] === '') {
            form.controls[fileUploadNameKey.key].setValue(`${fileConfigKey.defaultTextValue}`);
          }
          formData.append(fileConfigKey.text, form.value[fileUploadNameKey.key]);
          formData.append(fileConfigKey.key, event.target.files.item(0));
          formData.append('type', fileConfigKey.type);
          formData.append(ApplicationConstant.formMetaFields.userId, this.commonService.getUId().toString());
          formData.append('filename', AppUtil.createFileName(this.commonService.getUId(), fileConfigKey.name));

          this.saveDetails(ApplicationConstant.endpoint.UPLOAD_USER_FILE_URL, formData)
            .subscribe(value => {
              event.target.value = null;
              AppUtil.singleFileUpload(value.data, form.value[fileUploadNameKey.key], fileUpload);
            }, error => {
              console.log('error', error);
              event.target.value = null;
              this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
            });
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
            ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
      }
    } else {
      event.target.value = null;
      this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
    }
  }

  /**
   * Single Upload file.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param fileUpload file to be uploaded
   */
  singleUploadFile(event, fileConfigKey: any, fileUpload: FileUpload) {

    // check for no. of files
    if (Object.keys(fileUpload).length < 2) {
      // check for the file size less than 5MB.
      if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

        // check for file extension.
        if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

          const formData: FormData = new FormData();
          formData.append(fileConfigKey.key, event.target.files.item(0));
          formData.append('type', fileConfigKey.type);

          // userId related to User Profile Changes
          formData.append(ApplicationConstant.formMetaFields.userId, fileConfigKey?.userId ? fileConfigKey?.userId : this.commonService.getUId().toString());          
          formData.append('filename', AppUtil.createFileName(this.commonService.getUId(), fileConfigKey.name));

          this.saveDetails(ApplicationConstant.endpoint.UPLOAD_USER_FILE_URL, formData)
          .subscribe(value => {
            event.target.value = null;
            fileUpload.url = value.data.url;
            fileUpload.id = value.data.image_id;
            fileUpload.name = fileConfigKey.defaultTextValue;
            fileUpload.notifier = ApplicationNotifierConstant.file_upload;
          }, error => {
            console.log('error', error);
            event.target.value = null;
            this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
          });
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
            ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
      }
    } else {
      event.target.value = null;
      this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
    }
  }

  /**
   * Remove the file.
   *
   * @param fileUploadKey configuration
   * @param uploadFile file upload details
   * @param uploadFileList remove file from the file array.
   * @param index removing element position in the array.
   */
  removeFile(fileUploadKey: any, uploadFile: any, uploadFileList: Array<FileUpload>, index: number) {
    const formData = {
      [ApplicationConstant.formMetaFields.userId]: this.commonService.getUId(),
      [ApplicationConstant.formMetaFields.type]: fileUploadKey.type,
      [fileUploadKey.deleteKey]: uploadFile.id
    };
    this.removeDetails(ApplicationConstant.endpoint.REMOVE_FILE_URL, formData).subscribe(value => {
        uploadFileList.splice(index, 1);
    }, error => {
      console.log('error', error);
    });
  }

  /**
   * Get the course details of different courses with the application type and status.
   *
   * @param courseList Array of courses.
   */
  getCourseDetails(courseList: Array<any>) {
    const courseDetails: Map<number, object> = new Map<number, object>();
    const groupCourseList = AppUtil.groupArrayByGivenKey(courseList, 'cid');
    Object.values(groupCourseList).forEach((courses: Array<any>) => {
      const course = {};
      courses.forEach(courseDetail => {
        Object.assign(course, {
          [courseDetail[ApplicationConstant.formMetaFields.applicationType]]:
            courseDetail[ApplicationConstant.formMetaFields.approvalStatus]
        });
      });
      courseDetails.set(courses[0][ApplicationConstant.formMetaFields.courseId], course);
    });
    return courseDetails;
  }

  /**
   * Check if the course id exist for the given application with the status.
   */
  checkIfCourseIdExist(value: any, type: string, status: string) {
    return value && Object.keys(value).includes(type) && value[type] === status;
  }

  /**
   * To check if the application is non editable.
   *
   * @param type application type
   * @param value application with status
   */
  checkCourseIdWithStatus(type: string, value: any) {
    if (type && value) {
      return ApplicationConstant.nonEditableStatus.includes(value[type]);
    }
  }

  /**
   * To check if the application exist with status.
   *
   * @param type application type
   * @param value application with status
   * @param status course status list.
   */
  checkCourseIdExistWithStatus(type: string, value: any, status: any) {
    if (type && value) {
      return value[type] && status.includes(value[type]);
    }
  }

  /**
   * Get the specific course details.
   *
   * @param courseList list of course for the user.
   * @param courseId for that user.
   */
  getSpecificCourseDetails(courseList: any, courseId: number) {
    const course = {};
    const groupCourseList = AppUtil.groupArrayByGivenKey(courseList, 'cid');
    groupCourseList[courseId].forEach(courseDetail => {
      Object.assign(course, {
        [courseDetail[ApplicationConstant.formMetaFields.applicationType]]:
          courseDetail[ApplicationConstant.formMetaFields.approvalStatus]
      });
    });
    return course;
  }

  /**
   * Check if the phone number is exist.
   *
   * @param contactNumber phone number
   * @param countryCode country code for the phone number
   */
  checkPhoneNumberExist(contactNumber: string, countryCode: string): Promise<boolean> {
    const formData = {
      [ApplicationConstant.formMetaFields.userId]: !this.commonService.getUId() ? null : this.commonService.getUId().toString(),
      [ApplicationConstant.formMetaFields.contactNumber]: contactNumber.toString().replace(/\s+/g, ''),
      [ApplicationConstant.formMetaFields.contactCountryCode]: countryCode,
    };
    return new Promise<boolean>((resolve, reject) => {
      this.validateDetails(ApplicationConstant.endpoint.VALIDATE_MOBILE_NO_URL, formData)
        .subscribe((response) => {
          if (response?.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, (error) => {
          if (!error?.error?.success) {
            resolve(false);
          }
          else {
            reject(error);
          }
        });
      });
  }
  
  /**
   * Element to get the focus.
   *
   * @param element Id of the element.
   */
  focusToElement(element: string) {
    document.getElementById(element).focus();
  }

  /**
   * Single Upload file.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param url file to be uploaded
   * @param tag for the module name
   * @return promise url of the upload file.
   */
  singleFileUpload(event, fileConfigKey: any, url: string, tag: string, cid?: string): Promise<string> {

    return new Promise( (resp, err) => {
      // check for no. of files
      if (url === null || url === undefined || url === '') {
        // check for the file size less than 5MB.
        if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize, fileConfigKey)) {

          // check for file extension.
          if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

            const formData: FormData = new FormData();
            formData.append(fileConfigKey.key, event.target.files.item(0));
            formData.append('type', fileConfigKey.type);
            formData.append('tag', tag);
            formData.append(ApplicationConstant.formMetaFields.userId, this.commonService.getUId().toString());
            if(cid) {
              formData.append(ApplicationConstant.formMetaFields.courseId, cid);
            }
            this.saveDetails(ApplicationConstant.endpoint.UPLOAD_FILE_URL, formData)
              .subscribe(value => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
                resp(value.data);
              }, error => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
                err(error);
              });
          } else {
            event.target.value = null;
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
            err(null);
          }
        } else {
          event.target.value = null;
          if (fileConfigKey?.sizeType === ApplicationConstant.sizeTypeKB) {
            this.applicationNotifierService.getNotifier(
              { 
                icon: 'error_outline',
                message: `File size should be less than ${fileConfigKey.maxFileSize}kb`
              });
          } else {
            this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
          }
          err(null);
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
        err(null);
      }
    });
  }

  /**
   * drag and drop Upload file.
   *
   * @param files for file uploaded
   * @param fileConfigKey  file config key
   * @param url file to be uploaded
   * @param tag for the module name
   * @return promise url of the upload file.
   */
   dragAndDropFileUpload(files, fileConfigKey: any, url: string, tag: string): Promise<any> {

    return new Promise( (resp, err) => {
      // check for no. of files
      if (url === null || url === undefined) {
        // check for the file size less than 5MB.
        if (AppUtil.checkFileSize(files.item(0), ApplicationConstant.maxFileSize)) {

          // check for file extension.
          if (AppUtil.checkFileExtension(files.item(0).name, fileConfigKey.extension)) {

            const formData: FormData = new FormData();
            formData.append(fileConfigKey.key, files.item(0));
            formData.append('type', fileConfigKey.type);
            formData.append('tag', tag);
            formData.append(ApplicationConstant.formMetaFields.userId, this.commonService.getUId().toString());

            this.saveDetails(ApplicationConstant.endpoint.UPLOAD_FILE_URL, formData)
              .subscribe(value => {
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
                resp(value.data);
              }, error => {
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
                err(error);
              });
          } else {
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
            err(null);
          }
        } else {
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
          err(null);
        }
      } else {
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
        err(null);
      }
    });
  }

  /**
   * File Upload.
   *
   * @param event for file uploaded
   * @param fileConfigKey  file config key
   * @param url file to be uploaded
   * @param tag for the module name
   * @return promise url of the upload file.
   */
  fileUpload(event, fileConfigKey: any, url: string, tag: string): Promise<string> {

    return new Promise( (resp, err) => {
      // check for no. of files
      if (url === null || url === undefined) {
        // check for the file size less than 5MB.
        if (AppUtil.checkFileSize(event.target.files.item(0), ApplicationConstant.maxFileSize)) {

          // check for file extension.
          if (AppUtil.checkFileExtension(event.target.files.item(0).name, fileConfigKey.extension)) {

            const formData: FormData = new FormData();
            formData.append(fileConfigKey.key, event.target.files.item(0));
            formData.append('type', fileConfigKey.type);
            formData.append('tag', tag);
            formData.append(ApplicationConstant.formMetaFields.userId, this.commonService.getUId().toString());

            this.saveDetails(ApplicationConstant.endpoint.UPLOAD_FILE_URL, formData)
              .subscribe(value => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_upload);
                resp(value.data);
              }, error => {
                event.target.value = null;
                this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.error_message);
                err(error);
              });
          } else {
            event.target.value = null;
            this.applicationNotifierService.getNotifier(this.applicationNotifierService.createNotifierMessage(
              ApplicationNotifierConstant.file_extension_error, fileConfigKey.extension));
            err(null);
          }
        } else {
          event.target.value = null;
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.file_size_error);
          err(null);
        }
      } else {
        event.target.value = null;
        this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.single_file_limit_error);
        err(null);
      }
    });
  }
}
